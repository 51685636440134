






















import Vue from 'vue'
import Router from 'vue-router'
const Home = () => import( '@/routes/Home')
const Purpose = () => import( '@/routes/Purpose')
const How = () => import( '@/routes/HowItWorks')
const Methodology = () => import( '@/routes/Methodology')
const Strategy = () => import( '@/routes/Strategy')
const Showcase = () => import( '@/routes/Showcase')
const Terms = () => import( '@/routes/Terms')
const NotFound = () => import( '@/routes/NotFound')
const Team = () => import( '@/routes/Team')
const Contact = () => import( '@/routes/Contact')
const FocusAreas = () => import( '@/routes/FocusAreas')
const SanCosme = () => import( '@/routes/SanCosme')


Vue.use(Router)

export default new Router({
  routes: [
    {
      path: '/',
      name: 'home',
      component: Home
    },
    {
      path: '/how',
      name: 'how',
      component: How
    },
    {
      path: '/area',
      name: 'area',
      component: FocusAreas
    },
    {
      path: '/purpose',
      name: 'purpose',
      component: Purpose
    },
    {
      path: '/methodology',
      name: 'methodology',
      component: Methodology
    },
    {
      path: '/strategy',
      name: 'strategy',
      component: Strategy
    },
    {
      path: '/showcase',
      name: 'showcase',
      component: Showcase
    },
    {
      path: '/terms',
      name: 'terms',
      component: Terms
    },
    {
      path: '/404',
      name: '404',
      component: NotFound
    },
    {
      path: '/team',
      name: 'team',
      component: Team
    },
    {
      path: '/contact',
      name: 'contact',
      component: Contact
    },
    {
      path: '/sancosme',
      name: 'sancosme',
      component: SanCosme
    },
  ],
  mode: 'history'
})
