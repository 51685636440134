<template>
    <div class="footer animation visible-md visible-lg hidden-sm hidden-xs">
        <div v-html="translate[language].footerCopyright" style="font-size:11px; color:#fff; margin-top:10px; float:left;">Han Capital is a registered brand</div>
        <SocialLinks class="hide pull-right"></SocialLinks>
    </div>
</template>
<script>
export default {
    props: ['translate','language'],
}
</script>


    