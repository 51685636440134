<template>
  <div id="web">
    <Header class="slide-bottom" :isNavigationOpen="isNavigationOpen" @onCloseNavigation="onCloseNavigation" :translate="translate" :language="language" :languageRevert="languageRevert" @initTranslate="initTranslate" />
    <Navigation :isNavigationOpen="isNavigationOpen" @onCloseNavigation="onCloseNavigation" :translate="translate" :language="language" :languageRevert="languageRevert" @initTranslate="initTranslate"/>
      <router-view ></router-view>
    <Footer :translate="translate" :language="language" v-if="!['sancosme'].includes($route.name)" />
  </div>
</template>
<script>
export default {
  name: 'app',
  data(){
    return{
      isNavigationOpen: false,
      language : ( this.$cookies.get('language') == null  ? 'spanish'  : this.$cookies.get('language') ),
      languageRevert : ( this.$cookies.get('language') == null  ? 'Spanish Version'  : 'Versión Inglés' ),
      translate : {
          spanish: {
              purpose: '<div>Propósito</div>',
              works: '<div class="hidden-sm hidden-xs" >Cómo Funciona</div><div class="visible-sm visible-xs" >Cómo Funciona</div>',
              projects: '<div class="hidden-sm hidden-xs">Proyectos</div> <div class="visible-sm visible-xs" style="margin-left: -5px;">Proyectos</div>',
              sancosme: '<div class="hidden-sm hidden-xs">San Cosme</div> <div class="visible-sm visible-xs" style="margin-left: -5px;">San Cosme</div>',
              team : '<div class="hidden-sm hidden-xs">Equipo HAN</div> <div  class="visible-sm visible-xs" style="margin-left: 14px;">Equipo HAN</div>',
              contact: '<div class="hidden-sm hidden-xs">Contacto</div> <div  class="visible-sm visible-xs"  style="margin-left: -40px;">Contacto</div>',
              terms : '<div class="hidden-sm hidden-xs">Legal</div> <div  class="visible-sm visible-xs" style="margin-left: -10px;">Legal</div>',
              area : '<div class="hidden-sm hidden-xs">Zonas de Enfoque</div> <div  class="visible-sm visible-xs" style="margin-left: 30px;">Zonas de Enfoque</div>',
              translate : '<div class="hidden-sm hidden-xs">Versión Inglés</div> <div class="visible-sm visible-xs"  style="margin-left: -40px;">Versión Inglés</div>',
              footerCopyright: 'HAN Capital Here & Now es una marca registrada'

          },
          english: {
              purpose: '<div>Our Purpose</div>',
              works: '<div class="visible-md visible-lg">How it Works</div> <div class="visible-sm visible-xs" style="margin-left: 25px;">How it Works</div>',
              projects: '<div>Our Projects</div>',
              projects: '<div>San Cosme</div>',
              team : "<div>HAN&rsquo;s Team</div>",
              contact: '<div class="visible-md visible-lg" >Contact</div> <div  class="visible-sm visible-xs"  style="margin-left: 10px;">Contact</div>',
              terms : '<div class="visible-md visible-lg">Legal</div> <div class="visible-sm visible-xs"  style="margin-left:0px">Legal</div>',
              area : '<div>Focus Areas</div>',
              translate : '<div>Spanish Version</div>',
              footerCopyright: 'HAN Capital Here & Now is a registered brand'
          }
      },
    }
  },
  methods:{
     initTranslate( translate ){
         var self  =this
              switch( translate  ){
                case null:
                    this.$cookies.set('language','spanish');
                    this.languageRevert = 'Versión Inglés'
               break;
               case '':
                    this.$cookies.set('language','spanish');
                    this.languageRevert = 'Versión Inglés'
                break;
                case 'english':
                    this.$cookies.set('language','english');
                    this.languageRevert = 'Versión Inglés'
                break;
                case 'spanish':
                    this.$cookies.set('language','spanish');
                    this.languageRevert = 'Spanish Version'
                break;
              }
          console.log(  self.$cookies.get('language'))
          this.$forceUpdate();
        },
    onCloseNavigation(){
        this.isNavigationOpen = !this.isNavigationOpen
    },
    checkLanguage(){
        let self = this 
        setInterval( function(){
            self.language = ( self.$cookies.get('language') == null ? 'english' : self.$cookies.get('language')  )   // return value      
        }, 1000 )
    },
  },
  created(){
    this.checkLanguage()
    this.initTranslate( self.$cookies.get('language')  )
  }
}
</script>
