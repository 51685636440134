<template>
    <div class="header animation">
        <router-link tag="a" :to="{ name: 'home'}" aria-label="home"> 
            <img src="/images/logo.png" class="slide-bottom logo visible-md visible-lg visible-sm visible-xs" alt="">
        </router-link>
        <a href="#" class="mobile-navigation pull-right hidden-md hidden-lg visible-sm visible-xs" @click="onCloseNavigation" aria-label="navigation">
            <div class="line"></div>
            <div class="line"></div>
            <div class="line"></div>
        </a>
        <ul class="header-links visible-md visible-lg hidden-sm hidden-xs">
            <li class="slide-top animation delay-1">
                <router-link tag="a" to="purpose" class="header-nav-links" v-html="translate[language].purpose"></router-link>
            </li>
            <li class="slide-top animation delay-2">
                <router-link tag="a" to="how" class="header-nav-links" v-html="translate[language].works"> </router-link>
            </li>
            <li class="slide-top animation delay-3">
               <router-link tag="a" to="area" class="header-nav-links" v-html="translate[language].area">  </router-link>
            </li>
            <li class="slide-top animation delay-3">
               <router-link tag="a" to="showcase" class="header-nav-links" v-html="translate[language].projects">  </router-link>
            </li>
            <li class="slide-top animation delay-3">
               <router-link tag="a" to="sancosme" class="header-nav-links" v-html="translate[language].sancosme">  </router-link>
            </li>
            <li class="slide-top animation delay-4">
                <router-link tag="a" to="team" class="header-nav-links" v-html="translate[language].team">  </router-link>
            </li>
            <li class="slide-top animation delay-6">
                <a href="#" @click="onCloseNavigation">
                    <div class="square"> </div>
                    <div class="square"></div> 
                    <div class="square"></div> 
                </a>
            </li>
        </ul>
        <Contact :isModalOpen="isModalOpen" @onClose="onClose" :translate="translate" :language="language"/>
    </div>
</template>
<script>
export default {
    props: ['isNavigationOpen' , 'translate', 'language' , 'languageRevert' ],
    data(){
        return {
            isModalOpen : false,
        }
    },
    methods:{
        initTranslate(){
            this.$emit('initTranslate', this.language)
        },
        onClose(){
            this.isModalOpen = !this.isModalOpen
        },
        onCloseNavigation(){
            this.$emit('onCloseNavigation', !this.isNavigationOpen)
        },
    },
}
</script>

