<template>
     <ul class="social-links">
        <li class="slide-top animation delay-1">
            <a href="#" class="social " :class="{ 'colored-twitter' : isColored, 'twitter' : !isColored}"> &nbsp; </a>
        </li>
        <li class="slide-top animation delay-2">
            <a href="#" class="social" :class="{ 'colored-facebook' : isColored, 'facebook' : !isColored}"> &nbsp; </a>
        </li>
        <li class="slide-top animation delay-3">
            <a href="#" class="social" :class="{ 'colored-instagram' : isColored, 'instagram' : !isColored}"> &nbsp; </a>
        </li>
        <li class="slide-top animation delay-4">
            <a href="#" class="social" :class="{ 'colored-googleplus' : isColored, 'googleplus' : !isColored}"> &nbsp; </a>
        </li>
        <li class="slide-top animation delay-5">
            <a href="#" class="social" :class="{ 'colored-linkedin' : isColored, 'linkedin' : !isColored}"> &nbsp; </a>
        </li>
    </ul>
</template>
<script>
export default {
    props: ['isColored']
}
</script>

