<template>
    <ul class="works-sidebar">
        <router-link tag="li" :to="{name: 'how'}" @click="setLink(0)" >  {{ translate[language].sidebar.works.title  }} </router-link>
            <ul v-show="activeLink == 'how'">
                <li :class="{'active' : activeSublink == 0 && activeLink == 'how'}" @click="setSubLink(0)"> <a href="#"  @click="setSubLink(0)"> {{ translate[language].sidebar.works.subItems[0]  }}  </a> </li>
                <li :class="{'active' : activeSublink == 1 && activeLink == 'how'}" @click="setSubLink(1)"> <a href="#"  @click="setSubLink(1)"> {{ translate[language].sidebar.works.subItems[1]  }}  </a> </li>
                <li :class="{'active' : activeSublink == 2 && activeLink == 'how' }" @click="setSubLink(2)" > <a href="#"  @click="setSubLink(2)"> {{ translate[language].sidebar.works.subItems[2]  }}  </a> </li>
            </ul>            
        <router-link tag="li" :to="{name: 'methodology'}"  @click.native="setLink('')" >   {{ translate[language].sidebar.methodology.title  }} </router-link>
            <ul v-show="activeLink == 'methodology'">
                <li :class="{'active' : activeSublink == 'economic'}" @click="setSubLink('economic')" > <a href="#" @click="setSubLink('economic')">{{ translate[language].sidebar.methodology.subItems[0]  }} </a> </li>
                <li :class="{'active' : activeSublink == 'land'}" @click="setSubLink('land')"> <a href="#" @click="setSubLink('land')"> {{ translate[language].sidebar.methodology.subItems[1]  }} </a> </li>
                <li :class="{'active' : activeSublink == 'planning'}" @click="setSubLink('planning')"> <a href="#" @click="setSubLink('planning')"> {{ translate[language].sidebar.methodology.subItems[2]  }}  </a> </li>
                <li :class="{'active' : activeSublink == 'development'}" @click="setSubLink('development')"> <a href="#" @click="setSubLink('development')"> {{ translate[language].sidebar.methodology.subItems[3]  }}  </a></li>
                <li :class="{'active' : activeSublink == 'delivery'}" @click="setSubLink('delivery')"> <a href="#" @click="setSubLink('delivery')"> {{ translate[language].sidebar.methodology.subItems[4]  }} </a></li>
            </ul>   
        <router-link tag="li" :to="{name: 'strategy'}" @click.native="setLink(0)">  {{ translate[language].sidebar.strategy.title  }} </router-link>
            <ul v-show="activeLink == 'strategy'">
                <li :class="{'active' : activeSublink == 0 && activeLink == 'strategy'}"  @click="setSubLink(0)" > <a href="#" @click="setSubLink(0)"> {{ translate[language].sidebar.strategy.subItems[0]  }}  </a> </li>
                <li :class="{'active' : activeSublink == 1 && activeLink == 'strategy' }" @click="setSubLink(1)"> <a href="#" @click="setSubLink(1)"> {{ translate[language].sidebar.strategy.subItems[1] }} </a> </li>
            </ul>   
    </ul>
</template>
<script>
export default {
    props: ['activeLink', 'activeSublink'],
    data(){
        return {
            language : this.$cookies.get('language') == null ? 'english' : this.$cookies.get('language'),
            translate :{
                spanish : {
                     sidebar : {
                        works: {
                            title : 'CÓMO FUNCIONA EL FONDO',
                            subItems:[
                                'JUNTA DE ACCIONISTAS',
                                'COMITÉ TÉCNICO',
                                'EQUIPO DIRECTIVO'
                            ]
                        },
                        methodology: {
                            title : 'METODOLOGÍA',
                            subItems:[
                                'IDENTIFICACIÓN DEL PROYECTO',
                                'ADQUISICIÓN DE TIERRA',
                                'PLANEACIÓN',
                                'DESARROLLO/ ADMINISTRACIÓN',
                                'ENTREGAS/ RESULTADOS'
                            ]
                        },
                        strategy: {
                            title : 'ESTRATEGIA',
                            subItems:[
                                'PORTAFOLIO DIVERSO',
                                'DIVERSIFICACIÓN',
                            ]
                        }
                    }
                },
                english : {
                     sidebar : {
                        works: {
                            title : 'HOW DOES THE FUND WORK',
                            subItems:[
                                'SHAREHOLDERS COMMITTEE',
                                'TECHNICAL COMMITTEE',
                                'MANAGEMENT'
                            ]
                        },
                        methodology: {
                            title : 'METHODOLOGY',
                            subItems:[
                                'ECONOMIC ACTIVATION',
                                'LAND AQUISITION',
                                'PLANNING',
                                'DEVELOPMENT MANAGEMENT',
                                'DELIVERING RETURNS'
                            ]
                        },
                        strategy: {
                            title : 'STRATEGY',
                            subItems:[
                                'BROAD PORTFOLIO',
                                'DIVERSIFICATION',
                            ]
                        }
                    }
                },

            }
           
        }
    },
    methods:{
        setLink( activeLink ){
            this.$emit( 'setLink', activeLink )
        },
        setSubLink( activeSublink ) {
            this.$emit( 'setSubLink', activeSublink )
        },
        checkLanguage(){
            let self = this 
            setInterval( function(){
                self.language = ( self.$cookies.get('language') == null ? 'english' : self.$cookies.get('language')  )   // return value      
            }, 1000 )
        },
        
    },
    created(){
        this.checkLanguage()
    }
}
</script>
