import Vue from 'vue'
import App from './Web.vue'
import router from './routes.js'
import VueCookies from 'vue-cookies'

import VueFullPage from 'vue-fullpage.js'

Vue.use(VueFullPage);
Vue.use(VueCookies)

VueCookies.config('7d')

/* CSS */
import 'normalize.css';
import 'bootstrap-grid';
import './assets/css/style.css'
import './assets/css/animations.css'
import './assets/scss/app.scss'


import Header from './components/Header.vue';
import Footer from './components/Footer.vue';
import SidebarWorks from './components/SidebarWorks.vue';
import SocialLinks from './components/SocialLink.vue';
import Contact from './components/Contact.vue';
import Navigation from './components/Navigation.vue';
import NavigationWorks from './components/NavigationWorks.vue';

Vue.component('Header', Header );
Vue.component('Footer', Footer );
Vue.component('SidebarWorks', SidebarWorks );
Vue.component('SocialLinks', SocialLinks );
Vue.component('Contact', Contact );
Vue.component('Navigation', Navigation );
Vue.component('NavigationWorks', NavigationWorks );

Vue.config.productionTip = true
new Vue({
  render: h => h(App),
  router,
}).$mount('#app')
