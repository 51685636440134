<template>
    <div v-show="isNavigationOpen" class="modal navigation animation" :class="{'pos-from-top-bottom' :  isNavigationOpen }">
         <a href="#" class="navigation-close" @click="onCloseNavigation" :class="{'fade-in delay-4' : isNavigationOpen}"> 
            <img src="/images/icon-close.png">
        </a>
       <ul>
            <li @click="goToRoute('purpose')" class="hidden-md hidden-lg visible-sm visible-xs item" :class="{'fade-in delay-12' : isNavigationOpen}" @click.native="onCloseNavigation" v-html="translate[language].purpose"> </li>
            <li @click="goToRoute('how')"  class="hidden-md hidden-lg visible-sm visible-xs item" :class="{'fade-in delay-12' : isNavigationOpen}" @click.native="onCloseNavigation" v-html="translate[language].works"> </li>
            <li @click="goToRoute('area')" class="hidden-md hidden-lg visible-sm visible-xs item" :class="{'fade-in delay-12' : isNavigationOpen}" @click.native="onCloseNavigation" v-html="translate[language].area"></li>
            <li @click="goToRoute('showcase')" class="hidden-md hidden-lg visible-sm visible-xs item" :class="{'fade-in delay-12' : isNavigationOpen}" @click.native="onCloseNavigation" v-html="translate[language].projects"> </li>
            <li @click="goToRoute('sancosme')" class="hidden-md hidden-lg visible-sm visible-xs item" :class="{'fade-in delay-12' : isNavigationOpen}" @click.native="onCloseNavigation" v-html="translate[language].sancosme"> </li>            
            <li @click="goToRoute('team')"  class="hidden-md hidden-lg visible-sm visible-xs item" :class="{'fade-in delay-12' : isNavigationOpen}" @click.native="onCloseNavigation" v-html="translate[language].team"> </li>
            <li @click="goToRoute('contact')" class="item" :class="{'fade-in delay-12' : isNavigationOpen}" v-html="translate[language].contact"> </li>
            <li @click="goToRoute('terms')" class="item" :class="{'fade-in  delay-12' : isNavigationOpen}" @click.native="onCloseNavigation" v-html="translate[language].terms"> </li>
            <li class="item" :class="{'fade-in delay-12' : isNavigationOpen}" @click="initTranslate( ( language == 'english' ? 'spanish' : 'english' ) )"   v-html="translate[language].translate">  </li>
        </ul> 
    </div>
</template>
<script>
export default {
    props: ['isNavigationOpen' , 'translate', 'language' , 'languageRevert' ],
    methods:{
        onCloseNavigation(){
            this.$emit('onCloseNavigation', this.isNavigationOpen)
        },
        goToRoute( route){
            var self =  this
            this.$router.push({ name: route })
            setTimeout( function(){
                 self.$emit('onCloseNavigation', false)
            }, 200 )
        },
        initTranslate( language ){
            console.log( language )
            var self =  this
            this.$emit('initTranslate', language )
            setTimeout( function(){
                self.$emit('onCloseNavigation', false)
                self.$router.push({ name: 'home' })
             }, 200 )
        },
    }
}
</script>
