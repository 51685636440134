<template>
    <div class=" contact" v-show="isModalOpen" :class="{ 'modal' : !isPage , 'page':isPage}">
        <div class="container">
            <div class="col-md-offset-3">
                <div class="col-md-6 npm  contact-left">
                    <h1 class="slide-top" v-html="translate[language].title"></h1>
                    <div class="contact-information">
                        <ul>
                            <li>  <a href="tel:5517108196"> <i class="fa fa-phone"> </i> 5517108196 </a> </li>
                            <li>  <a href="mailto:squintana@han.mx"> <i class="fa fa-envelope"> </i> squintana@han.mx </a> </li>
                        </ul>
                    </div>
                </div>
            </div>
        </div>

    </div>
</template>
<script>
export default {
    props: [ 'isModalOpen', 'isPage' ,'translate', 'language' ],
    methods: {
        onClose(){
            this.$emit('onClose', this.isModalOpen )
        }
    }

}
</script>