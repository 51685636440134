<template>
    <div class="position:relative">
        <ul class="navigation works-mobile">
            <router-link tag="li" :to="{name: 'how' }" > 1 </router-link>
            <router-link tag="li" :to="{name: 'methodology' }" >  2 </router-link>
            <router-link tag="li" :to="{name: 'strategy' }" >  3 </router-link>
        </ul>
        <div style="position:absolute;bottom: 48%; width:100%" v-if="enableNav">
            <a href="#" class="circle-left" @click="setSubLink( activeSublink - 1) " v-if="activeSublink != 0">
                <img src="/images/scroll-icon.png" alt="" class="icon-left" >
            </a>
             <a href="#" class="circle-right" @click="setSubLink( activeSublink + 1)" v-if="activeSublink != arrArray.length -1 ">
                <img src="/images/scroll-icon.png" class="icon-right">
            </a>
        </div>
    </div>
</template>
<script>
export default {
    props: ['activeLink', 'activeSublink', 'enableNav' , 'arrArray'],
    data(){
        return {
            language : this.$cookies.get('language') == null ? 'english' : this.$cookies.get('language'),
        }
    },
    methods:{
        setLink( activeLink ){
            this.$emit( 'setLink', activeLink )
        },
        setSubLink( activeSublink ) {
            this.$emit( 'setSubLink', activeSublink )
        },
        checkLanguage(){
            let self = this 
            setInterval( function(){
                self.language = ( self.$cookies.get('language') == null ? 'english' : self.$cookies.get('language')  )   // return value      
            }, 1000 )
        },
        
    },
    created(){
        this.checkLanguage()
    }
}
</script>


